<template>
  <v-container fluid>
    <div class="select__container mt-2">
      <v-select dense outlined rounded class="selectbox"
          id="farm" 
          ref="farm" 
          v-model="farm"
          :items="farmItems"
          label="경영체" 
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeFarm()"
          @focus="comboFarms()"
        />
      <v-btn class="normal_btn ml-2 mt-1" @click="refresh()" :loading="loading">조회</v-btn>
    </div>

    <div class="alertSet__container">
      <div class="gab__container" v-for="(item,i) in items" :key="i">
        <span class="building__name">
          <img :src="farm_icon" class="farm_icon">
          {{ item.building.name }}
        </span>

      <div class="room_content">
        <div v-for="(room, r) in item.rooms" :key="r">
          <div class="room_setting">
            <div class="detail__container" style="justify-content: space-between;">
              <h3 class="building__name" style="background:#EDEFE5 !important; font-size:16px;">{{ room.room.name  }}</h3>
              <v-btn @click=save(i,r) v-if="room.showBtn" class="room_btn">저장</v-btn>
            </div>

            <div class="value__container">
              <div class="set__container" style="margin-top:-5px !important; ">
                <div class="detail__container mt-1 mr-3">
                  <v-icon color="#3a4f3f" size="16">
                    mdi-checkbox-marked-circle-outline
                  </v-icon>
                  <div class="set_title ml-1"> 내・외부 온도 격차 알림 </div>
                </div>
                <v-switch
                    v-model="room.room_outside_yn"
                    @change="checkValue(i, r)"
                    hide-details
                    class="switch mt-0 ml-1"
                    color="#3a4f3f"
                    inset
                  ></v-switch>
                </div>

                <div class="set__container mt-2">
                  <v-select dense outlined rounded
                    class="roomSelect mr-1"
                    id="base_room" 
                    ref="base_room" 
                    label="기준돈방" 
                    v-model="room.base_room"
                    :disabled="room.room_outside_yn == false"
                    :items="roomItems"
                    :menu-props="{ top: false, offsetY: true }"
                    no-data-text="자료(권한)이 없습니다."
                    item-text="name"
                    @change="changeRoom(i)"
                    return-object
                    @input="checkValue(i, r)"
                  >
                    <!-- 선택된 항목 슬롯을 사용하여 선택된 항목의 폰트 크기를 변경 -->
                    <template v-slot:selection="{ item, index }">
                      <div :key="index" class="custom-selection">
                        {{ truncateText(item.name) }}
                      </div>
                    </template>
                  </v-select>

                  <v-text-field dense outlined rounded 
                    type="number"
                    id="room_outside_gap"
                    ref="room_outside_gap"
                    class="setting_textbox shrink mr-1"
                    label="온도차"
                    suffix="°C"
                    min="1"
                    max="20"
                    :disabled="room.room_outside_yn == false"
                    v-model="room.room_outside_gap"
                    @input="checkValue(i, r)"/>

                  <v-text-field dense outlined rounded 
                    type="number"
                    id="outside_term"
                    ref="outside_term"
                    class="setting_textbox shrink mr-1"
                    label="알림주기"
                    suffix="분"
                    min="0"
                    :disabled="room.room_outside_yn == false"
                    v-model="room.outside_term"
                    @input="checkValue(i, r)"/>
                </div>
            </div>

            <div class="value__container" >
              <div class="set__container">
                <div class="detail__container mt-1 mr-3">
                  <v-icon color="#3a4f3f" size="16">
                    mdi-checkbox-marked-circle-outline
                  </v-icon>
                  <div class="set_title ml-1">내부 온도변화 알림</div>
                </div>
                <v-switch
                  v-model="room.room_changeup_yn"
                  hide-details
                  @change="checkValue(i, r)"
                  class="mt-0 ml-1"
                  color="#3a4f3f"
                  inset
                ></v-switch>
                </div>

              <div class="set__container mt-2">
                <v-text-field dense outlined rounded
                  type="number"
                  suffix="°C"
                  id="room_changeup_min"
                  ref="room_changeup_min"
                  class="setting_text centered-input shrink mr-2"
                  label="하한온도"
                  min="0"
                  max="30"
                  :disabled="room.room_changeup_yn == false"
                  v-model="room.room_changeup_min"
                  @input="checkValue(i, r)"/>

                <v-text-field dense outlined rounded
                  type="number"
                  suffix="°C"
                  id="room_changeup_max"
                  ref="room_changeup_max"
                  class="setting_text centered-input shrink mr-2"
                  label="상한온도"
                  min="0"
                  max="30"
                  :disabled="room.room_changeup_yn == false"
                  v-model="room.room_changeup_max"
                  @input="checkValue(i, r)"/>

                <v-text-field dense outlined rounded
                  type="number"
                  id="abnormal_temp_term"
                  ref="abnormal_temp_term"
                  class="setting_text shrink"
                  label="알림주기"
                  suffix="분"
                  min="0"
                  :disabled="room.room_changeup_yn == false"
                  v-model="room.abnormal_temp_term"
                  @input="checkValue(i, r)"/>
              </div>
            </div>

            <div class="value__container" >
              <div class="set__container">
                <div class="detail__container mt-1 mr-3">
                  <v-icon color="#3a4f3f" size="16">
                    mdi-checkbox-marked-circle-outline
                  </v-icon>
                  <div class="set_title ml-1">데이터 수신 불량 알림</div>
                </div>
                <v-switch
                  v-model="room.no_data_yn"
                  hide-details
                  @change="checkValue(i, r)"
                  class="mt-0 ml-1"
                  color="#3a4f3f"
                  inset
                ></v-switch>
                </div>

              <div class="set__container mt-2">
                <v-text-field dense outlined rounded
                  type="number"
                  id="no_data_term"
                  ref="no_data_term"
                  class="setting_text shrink"
                  style="max-width:100px;"
                  label="알림주기"
                  suffix="분"
                  min="0"
                  :disabled="room.no_data_yn == false"
                  v-model="room.no_data_term"
                  @input="checkValue(i, r)"/>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  </v-container>
</template>

<script>
import Common from "@/utils/custom/common.js";
import Apis from '@/api/apis';
import dateUtil from "@/utils/custom/dateProperty.js";
import VueCookies from "vue-cookies";

export default {
  name: "AlertSet",
  data: () => ({  
    loading:false,

    building: require("@/assets/monitoring/building.svg"),
    farm_icon: require("@/assets/monitoring/farm.svg"),

    farm : {},
    farmItems : [],
    roomItems : [],

    room_setup:{},

    items:[],
  }),
  async created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });

    await this.comboFarms();
    await this.comboRooms();

    let cookie_info = VueCookies.get("alertSet_info"); // 이전 조회정보를 참조

    if (cookie_info) {
        this.company = cookie_info.company || {};
        this.farm = cookie_info.farm || {};
        this.building = cookie_info.building || {};
    } else {  // 이전 조회정보가 없을 경우 첫번째 농장/사업장 디폴트로 참조
        this.farm = this.farmItems[0];
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
    }
    this.refresh();
  },
  methods:{
    truncateText(text) {
      const maxLength = 3;
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    },
    checkValue(i, r) {
      const room = this.items[i].rooms[r];
      const showBtn = room.room_outside_yn || room.base_room.name || room.room_outside_gap;
      this.$set(this.items[i].rooms[r], 'showBtn', !!showBtn);
    },
    refresh(){
      this.$store.commit("resMessage","");
      this.loading = true;

      Apis.listAlertSetupOfFarm({
        farm_cd: this.farm && this.farm.code,
        } ,(res) => {  // 정상처리
          this.items = res.data;

          this.comboRooms();

          let cookie_info = {company: this.company, farm: this.farm, building: this.building };
          VueCookies.set("alertSet_info" ,cookie_info,"30d");

          this.loading=false;
        }).catch( (err) => {  // 개별 API 오류 처리 예제
          console.log("listAlertSetupOfFarm 호출 오류",err);

          this.$router.push("/");
          this.loading = false;
        })
        this.changed = false;
    },
    checkData(){
      for (let i = 0; i < this.items.length; i++) {
        for (let r = 0; r < this.items[i].rooms.length; r++) {
          let room = this.items[i].rooms[r];

          if (room.room_outside_yn) {
            if (!room.base_room.code) {
              this.$refs.base_room[i].focus();
              return "기준돈방을 설정하세요.";
            }
            if (!Common.isNumeric(room.room_outside_gap)) {
              this.$refs.room_outside_gap[i].focus();
              return "기준돈방의 온도차를 입력하세요.";
            }
            if (!Common.isNumeric(room.outside_term)) {
              this.$refs.room_outside_gap[i].focus();
              return "기준돈방의 알림주기를 입력하세요.";
            }
            if (room.room_outside_gap < 1) {
              this.$refs.room_outside_gap[i].focus();
              return "양수를 입력해주세요.";
            }
            if (room.outside_term < 0) {
              this.$refs.outside_term[i].focus();
              return "정수를 입력하세요.";
            }
          }

          if (room.room_changeup_yn) {
            if (!Common.isNumeric(room.room_changeup_min)) {
              this.$refs.room_changeup_min[i].focus();
              return "하한온도를 입력하세요.";
            }
            if (!Common.isNumeric(room.room_changeup_max)) {
              this.$refs.room_changeup_max[i].focus();
              return "상한온도를 입력하세요.";
            }
            if (!Common.isNumeric(room.abnormal_temp_term)) {
              this.$refs.abnormal_temp_term[i].focus();
              return "알림주기를 입력하세요.";
            }
            if (room.room_changeup_min < -50) {
              this.$refs.room_changeup_min[i].focus();
              return "정확한 값을 입력해주세요.";
            }
            if (room.room_changeup_max < -50) {
              this.$refs.room_changeup_max[i].focus();
              return "정확한 값을 입력해주세요.";
            }
            if (room.abnormal_temp_term < 1) {
              this.$refs.abnormal_temp_term[i].focus();
              return "양수를 입력해주세요.";
            }
            if (room.room_changeup_min > room.room_changeup_max) {
              this.$refs.room_changeup_min[i].focus();
              return "상한온도보다 하한온도 값은 낮게 입력하세요.";
            }
          }
          if (room.no_data_yn) {
            if (room.no_data_term < 1) {
              this.$refs.no_data_term[i].focus();
              return "정수를 입력하세요.";
            }
          }
        }
      }
    },
    save(i,r){
      this.$store.commit("resMessage","");
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        return;
      }
      this.loading = true;
      
      const roomSetup = {
        company: this.items[i].company,
        farm: this.items[i].farm,
        building: this.items[i].building,
        room: this.items[i].rooms[r].room,

        room_outside_yn: this.items[i].rooms[r].room_outside_yn,
        base_room:  this.items[i].rooms[r].base_room,
        room_outside_gap: this.items[i].rooms[r].room_outside_gap,
        outside_term: this.items[i].rooms[r].outside_term,

        room_changeup_yn: this.items[i].rooms[r].room_changeup_yn,
        room_changeup_max: this.items[i].rooms[r].room_changeup_max,
        room_changeup_min: this.items[i].rooms[r].room_changeup_min,
        abnormal_temp_term: this.items[i].rooms[r].abnormal_temp_term,

        no_data_yn: this.items[i].rooms[r].no_data_yn,
        no_data_term: this.items[i].rooms[r].no_data_term,

        etc: this.items[i].rooms[r].etc,
        chgdate: this.items[i].rooms[r].chgdate,
        chguser: this.items[i].rooms[r].chguser,


        outside_limit_count: 1,
        abnormal_temp_limit_count: 1,
        no_data_limit_count: 1,

      };


      Apis.saveAlertSetupOfRoom({
      "room_setup" : roomSetup,
      },(res) => {
        // console.log(res)
        if (res.result) {
          this.$store.commit("resMessage",res.message);
          this.loadingSave = false;
          this.$set(this.items[i].rooms[r], 'showBtn', false);
          // this.showBtn = false;

          this.refresh();
        } else {
          this.loadingSave = false;
          console.log("저장 오류",res.message)
        }
      }).catch( (err) => {  // API 오류 처리
          this.loadingSave = false;
          console.log("saveAlertSetupOfRoom API 호출 오류",err)
          alert(err);
      })
    },
    async comboFarms() {
      await Apis.comboFarms({
        company_cd: "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            // console.log(this.farmItems)
            
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
            // alert(err);
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.company_cd) {
        this.building = {};
      }
      this.buildingItems = [];
      this.refresh();
    },

    async comboRooms() {
      await Apis.comboRooms({
        company_cd: "",
        farm_cd: this.farm && this.farm.code || "",
        building_cd: "",
        name_flag: true,
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.roomItems = [];
            // console.log(res.data)

            for (let i in res.data) {
            this.roomItems.push({
              code: res.data[i].room_cd,
              name: res.data[i].room_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboRooms API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeRoom() {      // 하위콤보 선택시 상위 콤보 동시 세팅
      this.base_room = {code:this.room_cd, name:this.room_name}
    },

  }
}
</script>

<style lang="scss" scoped>
h3{
  letter-spacing: -1px;
  color:#3a4f3f;
}
.select__container{
  display:flex;
  margin-left:10px;
}
.label{
  width:70px;
  margin-top:10px;
  font-size:18px;
  font-weight:700;
  color:#3a4f3f;
  // margin-rightpx;
}
.selectbox{
  max-width:200px !important;
  height:50px;
}
.normal_btn{
  border-radius: 30px;
  color:white !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.alertSet__container{
  max-width:100% !important;
}
.gab__container{
  border:1px solid #3a4f3f;
  background-color:#FCF9F0;
  margin:10px 10px 20px 10px;
  padding:10px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
}
.building__name{
  position:relative;
  top:-20px;
  background-color:#fbf2d5;
  color:#3a4f3f;
  font-size:20px;
  font-weight:700;
  letter-spacing: -1px;
  border-radius: 10px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
  padding:10px;
}
.alert__container, .value__container, .set__container ,.detail__container, .mb_container{
  display:flex;
}
.set__container{
  margin-top:10px;
}
.value__container{
  flex-direction: column;
  margin-top:-10px;
  border-radius: 10px;
  padding:5px;
}
.set_title{
  width:230px;
  font-size:15px;
  font-weight: 700;
  color:#3a4f3f;
  letter-spacing: -1px;
  // text-shadow: 1px 0px 0px gray; 
}
.explain{
  font-size:15px;
  letter-spacing: -0.74px;
  color:#767676;
  margin-bottom: 10px;
  margin-left:10px;
}
.roomSelect{
  min-width:120px;
}
.setting_text{
  min-width:100px !important;
}
.setting_textbox{
  min-width:95px;
}
::v-deep .setting_textbox input[type="number"]::-webkit-outer-spin-button,
::v-deep .setting_textbox input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
::v-deep .setting_textbox input[type="number"] {
  -moz-appearance: textfield;
}
.roomSelect, .setting_textbox , .setting_text{
  height:40px !important;
}

.setting{
  background-color:white;
  border:1px solid #3a4f3f;
  border-radius:10px;
  margin:10px;
  padding:10px;
  width:50%;
}
.btn__container{
  text-align: center;
}
.save_btn{
  width:90px !important;
  height:50px !important;
  font-size:18px;
  font-weight: 600;
  background-color:#3a4f3f !important;
  color:white;
  letter-spacing: -1px;
  border-radius: 30px !important;
}
.farm_icon{
  width:30px;
  height:30px;
  padding-top:10px;
}
.room_content{
  display: flex;
  flex-wrap: wrap;
  transform: translateX(1%);
}
.room_setting{
  max-width:330px;
  padding:10px 10px 10px 0;
  border-radius: 15px;
  border:1px solid #3a4f3f;
  background:white;
  margin:5px 5px 10px 5px;
}
.room_btn{
  width:50px !important;
  height:30px !important;
  font-size:15px;
  font-weight: 600;
  background-color:#3a4f3f !important;
  color:white;
  letter-spacing: -1px;
  border-radius: 30px !important;
}
::v-deep .v-text-field__suffix {
  font-size: 13px;
  letter-spacing: -1px;
}
.custom-selection {
  font-size:14px;
  letter-spacing: -1px;
}
.mb_container{
  margin-left:10px;
  min-width:200px;
}

@media screen and (max-width: 768px){
  .alert__container , .mb{
    display:block;
    margin: 0 auto; /* 중앙 정렬 */
  }
  .roomSelect{
    max-width:130px !important;
    margin: 0 auto; /* 중앙 정렬 */
  }
  .gab__container{
    margin-top:20px !important;
    margin: 0 auto; /* 중앙 정렬 */
  }
  .room_content{
    display: flex;
    flex-direction: column;
    align-items: center; /* 중앙 정렬 */
    margin: 0 auto; /* 추가적인 중앙 정렬 */
  }
  .room_setting{
    max-width:330px;
    margin: 0 auto; /* 중앙 정렬 */
    margin-top:10px !important;
    // transform: translateX(%);
  }
}
@media screen and (max-width: 360px){
  .alert__container{
    display:block;
    margin: 0 auto; /* 중앙 정렬 */
  }
  .roomSelect{
    width:110px !important;
    min-width:110px;
  }
  .set_title{
    width:210px;
  }
  .setting_textbox{
    min-width:85px !important;
  }
  .setting_text{
    min-width:90px !important;
  }
  .room_setting{
    width:300px;
    
    margin:0;
  }
  .gab__container{
    margin:0px;
    margin-top:10px;
  }
  .mb_container{
    display:flex;
  }
  ::v-deep .v-select__slot .v-label{
    font-size:12px !important;
    letter-spacing: -1px;
  }
  ::v-deep .v-select__selections input{
    display:none;
  }
  .custom-selection{
    margin-left:-10px !important;
  }
}
@media screen and (max-width: 320px){
  .room_setting{
    width:280px;
  }
  .set_title{
    width:180px;
  }
  .roomSelect{
    min-width:90px;
  }
  .set__container .setting_text{
    margin-right:1px !important;
  }
}

</style>